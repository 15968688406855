<template>
    <div class="propmt-card" v-if="isPromptVisible" :style="{'background-color': bgColor}">
        <img :src="require('../assets/' + propmtImg)">
        <div class="propmt-card-txt">
            <p class="title fw-700 fs-13 white-color" :style="{color : titleColor}">{{title}}</p>
            <div class="message fw-400 fs-13">
                <p v-html="message" class="white-color"></p>
            </div>
        </div>
        <div class="left-bar" :style="{'background-color': leftBarColor}"></div>
    </div>
</template>

<script>

export default {
  props: {
    selectedPromptOption: {
      type: Number,
    },
    isPromptVisible: {
      type: Boolean,
    },
  },
  data() {
    return {
      title: "",
      propmtImg: "",
      message: "",
      titleColor: "",
      leftBarColor: "",
      bgColor: "",
    };
  },
  watch: {
    selectedPromptOption() {
      this.selectPromptHandler(this.selectedPromptOption);
    },
  },
  created() {
    this.selectPromptHandler(this.selectedPromptOption);
  },
  methods: {
    selectPromptHandler(option) {
      switch (Number(option)) {
        case 0:
          this.title = "Concept Mastery!";
          this.propmtImg = "concept-mastry-prompt.svg";
          this.message = "You got <b>5 Tough Qs</b> correct in a concept";
          this.leftBarColor = "#00847B";
          this.bgColor = "#09A399";
          break;
        case 1:
          this.title = "Hey... Congrats!";
          this.propmtImg = "congrats-prompt.svg";
          this.message = "You just cracked a <b>Tough Nut</b>";
          this.leftBarColor = "#00847B";
          this.bgColor = "#09A399";
          break;
        case 2:
          this.title = "Winning Streak!";
          this.propmtImg = "winning-streak-prompt.svg";
          this.message = "Nice... <b>10 Correct Qs</b> in a row";
          this.leftBarColor = "#00847B";
          this.bgColor = "#09A399";
          break;
        case 3:
          this.title = "2X Winning Streak!";
          this.propmtImg = "winning-streak-prompt.svg";
          this.message = "Amazing... <b>20 Correct Qs</b> in a row";
          this.leftBarColor = "#00847B";
          this.bgColor = "#09A399";
          break;
        case 4:
          this.title = " Losing Streak!";
          this.propmtImg = "losing-streak-prompt.svg";
          this.message = "Be careful... you got <b>10 Qs Wrong</b> in row";
          this.leftBarColor = "#BA3838";
          this.bgColor = "#F06268";
          break;
        case 5:
          this.title = "Losing Streak!";
          this.propmtImg = "losing-streak-prompt.svg";
          this.message = "Uh oh... you got <b>20 Qs Wrong</b> in row";
          this.leftBarColor = "#BA3838";
          this.bgColor = "#F06268";
          break;
        case 6:
          this.title = "OOPS!";
          this.propmtImg = "oops-prompt.svg";
          this.message = "You just missed an <b> Easy </b> question";
          this.leftBarColor = "#BA3838";
          this.bgColor = "#F06268";
          break;
        case 7:
          this.title = "Keep Going!";
          this.propmtImg = "keep-going-prompt.svg";
          this.message = "Good work - you are practicing well";
          this.leftBarColor = "#00847B";
          this.bgColor = "#09A399";
          break;
        case 8:
          this.title = "Amazing Persistence!";
          this.propmtImg = "keep-going-prompt.svg";
          this.message = "Wow... your hard work will bear fruit";
          this.leftBarColor = "#00847B";
          this.bgColor = "#09A399";
          break;
        case 9:
          this.title = "High Accuracy";
          this.propmtImg = "accuracy-prompt.svg";
          this.message = "Your accuracy is good - keep going!";
          this.leftBarColor = "#00847B";
          this.bgColor = "#09A399";
          break;
        case 10:
          this.title = "Low Accuracy";
          this.propmtImg = "accuracy-prompt.svg";
          this.message = "Focus on getting the Qs right - take it slow";
          this.leftBarColor = "#BA3838";
          this.bgColor = "#F06268";
          break;
        case 11:
          this.title = "Pick Up The Pace";
          this.propmtImg = "pick-up-the-pace-prompt.svg";
          this.message = "You are going too slow";
          this.leftBarColor = "#BA3838";
          this.bgColor = "#F06268";
          break;
        case 12:
          this.title = "Keep Going!";
          this.propmtImg = "speedcoin-prompt.svg";
          this.message = "You are on track to earn <b>SpeEdCoins</b>";
          this.leftBarColor = "#00847B";
          this.bgColor = "#09A399";
          break;
        case 13:
          this.title = "Practice More To Earn More!";
          this.propmtImg = "speedcoin-prompt.svg";
          this.message = "Continue practicing for more <b>SpeEdCoins</b>";
          this.leftBarColor = "#00847B";
          this.bgColor = "#09A399";
          break;
        case 14:
          this.title = "Skipping Too Many Qs";
          this.propmtImg = "skipping-too-many-prompt.svg";
          this.message = "Hey... try attempting more Qs to learn";
          this.leftBarColor = "#BA3838";
          this.bgColor = "#F06268";
          break;
        case 15:
          this.title = "Skipped 5 In A Row";
          this.propmtImg = "skipping-too-many-prompt.svg";
          this.message = "Having trouble with a concept?";
          this.leftBarColor = "#BA3838";
          this.bgColor = "#F06268";
          break;
        default:
      }
    },
  },

};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap');

* {
    padding: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}
.white-color {
  color: #fff;
}

.propmt-card {
    width: 100%;
    border: 1px solid #E0E4F0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    height: 68px;
    position: relative;
}
.left-bar {
    content: "";
    border-radius: 0px 3px 3px 0px;
    position: absolute;
    left: 0;
    width: 3px;
    height: 44px;
}

.propmt-card img {
    width: 44px;
    height: 44px;
    object-fit: scale-down;
    background: #F3F5FC;
    border-radius: 50%;
    padding: 5px;
}
.message {
    color: #333;
}
</style>
